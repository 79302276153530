const Account = () => {
    return (  
        <>
        <a href="/">Home</a>
        <a href="/free">Free Component</a>
        <a href="/auth">Auth Component</a>
        </>
    );
}
 
export default Account;